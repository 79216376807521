import {
    faFacebookF,
    faTwitter,
    faInstagram,
    faLinkedinIn,
    faYoutube,
    faPinterest,
    faReddit,
    faTelegram,
    faWhatsapp,
    faTiktok,
    faSnapchat,
    faWeibo,
    faTumblr,
    faDribbble,
    faBehance,
    faFlickr,
    faVimeoV,
    faQuora,
    faMediumM,
    faDiscord,
    faGithub,
    faStackOverflow 
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

var Config = {

    app_key: 'qwe#r$s%s&d*r!w*e((f))d-f`werh14445`4rt5`4ert5`4rt31645k132v132',
    // api: 'https://api.flatcoding.com',
    api:'https://api.flatcoding.com', 
    wp_api: 'https://authors.flatcoding.com',
    wp_keys: {
        public: "v@8X#F^Tm$N2g*JW%4L7!5&pRqRMZ1\u003C3\u003E+n=;}{:[]|()_~-./?`'\"ABCDEFGHJKLMNOPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz0123456789",
        secret: "C&k*Q@5!L^XVmN1#Tg%4p7RqR2z+=-()[]{}:;|?\u003C\u003E\"~/`'1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz$^*"
    },
    cookie_name: 'user_info',
    //api:'http://localhost:3000/api', 
    captcha: {
        public: '6LefOUopAAAAAERp42vXnixQ6WAUcq7VAnyHjwlt',
        secret:'6LefOUopAAAAAKDP80U5BQ8v-U10T_H2IF0WCZ9P'
    },

    icons: { 
        facebook: faFacebookF,
        twitter: faTwitter,
        instagram: faInstagram,
        linkedin: faLinkedinIn,
        youtube: faYoutube,
        pinterest: faPinterest,
        reddit: faReddit,
        telegram: faTelegram,
        whatsapp: faWhatsapp,
        tiktok: faTiktok,
        snapchat: faSnapchat,
        weibo: faWeibo,
        tumblr: faTumblr,
        dribbble: faDribbble,
        behance: faBehance,
        flickr: faFlickr,
        vimeo: faVimeoV,
        quora: faQuora,
        medium: faMediumM,
        discord: faDiscord,
        github: faGithub,
        stackoverflow: faStackOverflow ,
        email: faEnvelope
    }
    
} 
 
export default Config; 
